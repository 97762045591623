#single-post {
    p {
        text-align: justify;
    }
}

@media (min-width: 768px) {
    #single-post {
        p {
            text-align: inherit;
        }
    }
}
