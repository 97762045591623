@for $i from 1 through 5 {
  .c-top-#{$i} {
    top: (0.75 * $i / 2) + rem !important;
  }
}

#toc {
  .nav {
     li {
       a {
        display: block;
        padding: .5rem 0;
        font-size: 70%;
        color: #767676;
        box-shadow: 0 1.5px 1.5px -1.5px rgba(0, 0, 0, 0.2);
        background-color: transparent;

        &:focus, &:hover {
          color: #709cde;
          text-decoration: none;
        }

        &.active {
          color: #709cde;
          font-weight: bolder;
        }
      }


      &:last-child {
         a {
           box-shadow: none;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #toc {
    box-shadow: 5px 0 5px -5px rgba(0,0,0,.25);
  }
}
